import React, { useState, useEffect } from "react";
import client, { urlFor } from "../../sanityCli";
import "../../../index.css";
import "./HomeSec8.css";

function HomeSec8() {
  const [sectionData, setSectionData] = useState(null);
  const [activeBoxIndex, setActiveBoxIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch('*[_type == "homeSec8"][0]');
        setSectionData(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchData();
  }, []);

  const toggleDescription = (index) => {
    if (isMobile) {
      setActiveBoxIndex((prevState) => (prevState === index ? null : index));
    } else {
      setActiveBoxIndex(index);
    }
  };

  if (!sectionData) return null;

  return (
    <div id="whyUs" className="why-us bg-[#353c33] relative my-[4%] py-[2%] flex flex-col items-center justify-center bg-image">
      <div className="overlay"></div>
      <div className="content flex flex-col items-center justify-center space-y-2 lg:space-y-4">
        <p className="montserrat text-[1rem] lg:text-[2rem] leading-[2.8rem] font-semibold text-white">
          {sectionData.title}
        </p>
        <h1 className="playfair-display text-center text-[1.5rem] leading-[2.1rem] lg:leading-[5.25vw] lg:text-[3.75vw] font-semibold px-[10%] text-white">
          {sectionData.subtitle}
        </h1>
      </div>

      <div className="content px-2 w-full flex lg:flex-row flex-col flex-wrap items-center justify-between gap-0 relative z-10 pb-8 lg:py-8">
        {sectionData.boxes.map((item, index) => (
          <div
            key={index}
            className={`box mt-8 w-[90%] lg:w-[19%] lg:h-80 p-4 rounded-lg flex flex-col items-center ${activeBoxIndex === index ? 'active' : 'inActive'}`}
            onClick={() => isMobile && toggleDescription(index)}
            onMouseEnter={() => !isMobile && setActiveBoxIndex(index)}
            onMouseLeave={() => !isMobile && setActiveBoxIndex(null)}
          >
            <div className={`icon-box w-[4rem] h-[4rem] 2xl:w-[6rem] 2xl:h-[6rem] rounded-full flex items-center justify-center ${activeBoxIndex === index ? 'active' : 'inActive'}`}>
              <img src={activeBoxIndex === index ? urlFor(item.hoverIcon).url() : urlFor(item.icon).url()} alt="icon" className="w-[2rem] h-[2rem]" />
            </div>
            <h1 className={`text-2xl leading-[75px] tracking-tight lg:text-[1.2vw] lg:leading-[3.4vw] 2xl:text-[2rem] 2xl:leading-[6rem] font-extrabold ${activeBoxIndex === index ? 'active' : 'inActive'}`}>
              {item.title}
            </h1>
            <p className={`montserrat tracking-wide text-center text-[1rem] leading-[2rem] lg:text-[10.706px] lg:leading-[22.751px] font-medium description ${isMobile && activeBoxIndex !== index ? "hidden" : ""}`}>
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeSec8;
