import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import client, { urlFor } from "../../sanityCli";
import { v4 as uuid4 } from "uuid";


function Footer() {
  const [content, setContent] = useState(null);
  const [footer, setFooter] = useState(null);
  const [emailData, setEmailData] = useState({ email: "" });

  const fetchData = async () => {
    try {
      const result = await client.fetch(
        '*[_type == "newsletterSubscription"][0]'
      );
      setContent(result);
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  const footerData = async () => {
    try {
      const result = await client.fetch('*[_type == "footer"][0]');
      setFooter(result);
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  useEffect(() => {
    fetchData();
    footerData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetchData();
    if (!content) return;

    client
      .patch(content._id)
      .setIfMissing({ subscribers: [] })
      .append("subscribers", [
        {
          ...emailData,
          _key: uuid4(),
        },
      ])
      .commit()
      .then(() => {
        alert("Form submitted successfully!");
        setEmailData({
          email: "",
        });
      });
  };

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  if (!footer) return null;

  return (
    <>
      <div
        className="hidden px-[5%] py-[5%] md:flex items-start justify-center gap-[10%]"
        style={{ background: "#F1F2F0" }}
      >
        <div className="footer-left w-2/5">
          <div className="flex items-start justify-between">
            <div className="company">
              {footer.company && (
                <h1 className="monstrrate text-[1vw] leading-[1.25vw] font-semibold tracking-wider text-gray-800">
                  {footer.company.title}
                </h1>
              )}
              <div className="mt-[1.5rem] flex flex-col items-start space-y-5">
                {footer.company.itme1 && (
                  <p
                    className="monstrrate cursor-pointer text-[1vw] leading-[1.25vw] font-normal tracking-wider text-gray-800"
                    onClick={() => handleNavLinkClick("about")}
                  >
                    {footer.company.itme1}
                  </p>
                )}
                {footer.company.itme2 && (
                  <p
                    className="monstrrate cursor-pointer text-[1vw] leading-[1.25vw] font-normal tracking-wider text-gray-800"
                    onClick={() => handleNavLinkClick("about")}
                  >
                    {footer.company.itme2}
                  </p>
                )}
                {footer.company.itme3 && (
                  <p
                    className="monstrrate cursor-pointer text-[1vw] leading-[1.25vw] font-normal tracking-wider text-gray-800"
                    onClick={() => handleNavLinkClick("about")}
                  >
                    {footer.company.itme3}
                  </p>
                )}
              </div>
            </div>
            <div className="getInTouch">
              {footer.getInTouch && (
                <h1 className="monstrrate text-[1vw] leading-[1.25vw] font-semibold tracking-wider text-gray-800">
                  {footer.getInTouch.title}
                </h1>
              )}
              <div className="mt-[1.5rem] flex flex-col items-start space-y-2">
                <p
                  className="monstrrate text-[1vw] cursor-pointer leading-[1.25vw] font-normal tracking-wider text-gray-800"
                  onClick={() => handleNavLinkClick("contact")}
                >
                  {footer.getInTouch.itme1}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col my-16">
            {footer.expoLogo && (
              <img
                src={urlFor(footer.expoLogo)}
                alt="/"
                className="w-[10vw] cursor-pointer"
                onClick={() => handleNavLinkClick("home")}
              />
            )}
            {footer.copyright && (
              <p className="monstrrate text-[1vw] leading-[1.25vw] font-normal tracking-wider text-gray-800 my-4">
                {footer.copyright}
              </p>
            )}
            <div className="flex items-center gap-8">
              {footer.legals && (
                <Link
                  to={footer.legals.itme2Path}
                  className="monstrrate text-[1vw] leading-[1.25vw] font-normal tracking-wider text-gray-800 underline"
                >
                  {footer.legals.itme2}
                </Link>
              )}
              {footer.legals && (
                <Link
                  to={footer.legals.itme3Path}
                  className="monstrrate  text-[1vw] leading-[1.25vw] font-normal tracking-wider text-gray-800 underline"
                >
                  {footer.legals.itme3}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="footer-right w-1/2 flex flex-col ">
          <div className="w-full">
            {footer.signUpTitle && (
              <label className="uppercase monstrrate text-[#2B3029] font-normal text-[1rem] leading-[1.5vw]">
                {footer.signUpTitle}
              </label>
            )}
            <span className="flex gap-2 full">
              <input
                type="text"
                placeholder="Your email"
                className="border-[1.333px] border-gray-400 w-3/4 rounded-sm px-4 text-[#313131] h-[3.25rem]"
                style={{ background: "#F1F2F0" }}
                value={emailData.email}
                name="email"
                onChange={handleInputChange}
              />
              {footer.subscribeBtn && (
                <button
                  className="uppercase flex justify-center items-center h-[3.25rem] icon-bg text-[#FAFAFA] w-[8rem] rounded-sm text-[0.8rem] tracking-wide monstrrate"
                  onClick={handleSubmit}
                >
                  {footer.subscribeBtn}
                </button>
              )}
            </span>
          </div>
          {footer.subscribeDesc && (
            <p className="text-sm text-[#313131] font-normal  mt-4 capitalize">
              {" "}
              {footer.subscribeDesc}
            </p>
          )}
          <div className="mt-[10vw] ml-[30vw]">
            {footer.indicLogo && (
               <Link to={footer.indicPath} target="blanck">
                {" "}
                <img
                  src={urlFor(footer.indicLogo)}
                  alt="/indiclogo"
                  className="hover:scale-105"
                />
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* mobile footer */}

      <div className="block md:hidden flex-col items-center justify-center w-full h-auto px-4 py-8 bg-[#F1F2F0]">
        {footer.expoLogo && <img
          src={urlFor(footer.expoLogo)}
          alt="/"
          className="w-[40vw] cursor-pointer mb-8"
          onClick={() => handleNavLinkClick("home")}
        />}
        <div className="w-full  flex items-start justify-center gap-4">
          <div className="w-full flex flex-col items-start justify-center">
            <h1 className="monstrrate text-[4vw] leading-[5vw] font-semibold tracking-wider text-gray-800">
              {footer.company.title}
            </h1>
            <div className="mt-[4vw] flex flex-col items-start space-y-4">
              <p
                className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-[#313131] cursor-pointer"
                onClick={() => handleNavLinkClick("about")}
              >
                {footer.company.itme1}
              </p>
              <p
                className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-[#313131] cursor-pointer"
                onClick={() => handleNavLinkClick("ourProducts")}
              >
                {footer.company.itme2}
              </p>
              <p
                className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-[#313131] cursor-pointer"
                onClick={() => handleNavLinkClick("whyUs")}
              >
                {footer.company.itme3}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col items-start justify-center">
            <h1 className="monstrrate text-[4vw] leading-[5vw] font-semibold tracking-wider text-gray-800">
              {footer.legals.title}
            </h1>
            <div className="mt-[4vw] flex flex-col items-start space-y-4">
              <Link
                to="/privacyPolicy"
                className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-gray-800 cursor-pointer"
              >
                {footer.legals.itme1}
              </Link>
              <Link
                to="/privacyPolicy"
                className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-gray-800 cursor-pointer"
              >
                {footer.legals.itme2}
              </Link>
              <Link
                to="/cookiePolicy"
                className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-gray-800 cursor-pointer"
              >
                {footer.legals.itme3}
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 my-12">
          <h1 className="monstrrate text-[4vw] leading-[5vw] font-semibold tracking-wider text-gray-800">
            {footer.getInTouch.title}
          </h1>
          <p
            className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-[#313131] cursor-pointer"
            onClick={() => handleNavLinkClick("contact")}
          >
            {footer.getInTouch.itme1}
          </p>
        </div>

        <div className="w-full flex flex-col items-start justify-center mt-8">
          <label className="uppercase monstrrate text-[#2B3029] font-normal text-[1rem] leading-[6vw]">
            {footer.signUpTitle}
          </label>
          <span className="flex items-center justify-center gap-1 my-3">
            <input
              type="text"
              placeholder="Your email"
              className="border-[1.8px] border-[#icon-bg] rounded-sm px-4 w-[65vw] text-[#313131] h-[12vw]"
              style={{ background: "#F1F2F0" }}
              value={emailData.email}
              name="email"
              onChange={handleInputChange}
            />
            <button
              className=" icon-bg  uppercase flex justify-center items-center h-[12vw]  text-[#FAFAFA] w-[28vw] rounded-sm text-[12px] tracking-wide monstrrate"
              onClick={handleSubmit}
            >
              {footer.subscribeBtn}
            </button>
          </span>
        </div>
        <div className=" flex flex-col space-y-6">
          <p className="text-[#313131] text-[8px] ml-2">
            {footer.subscribeDesc}
          </p>
          {footer.indicLogo && (
            <Link to={footer.indicPath} target="blanck">
              <img src={urlFor(footer.indicLogo)} alt="/indiclogo" />
            </Link>
          )}
          <p className="monstrrate text-[12px] text-[#313131]">
            {footer.copyright}
          </p>
        </div>

        <div className="flex items-center gap-4 my-16">
          <Link
            to="/privacyPolicy"
            className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-[#313131] underline"
          >
            {footer.legals.itme2}
          </Link>
          <Link
            to="/cookiePolicy"
            className="monstrrate text-[4vw] leading-[5vw] font-normal tracking-wider text-[#313131] underline ml-8"
          >
            {footer.legals.itme3}
          </Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
