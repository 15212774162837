import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import plus from "./plus.svg";
import minus from "./minus.svg";
import client, { urlFor } from "../sanityCli";

function DetailPage2() {
  const [content, setContent] = useState(null);
  const { productType } = useParams();
  const navigate = useNavigate();
  const sectionRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [readMoreVisibleSections, setReadMoreVisibleSections] = useState({});
  const asideRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const fetchData = async () => {
    try {
      const result = await client.fetch('*[_type == "productDetailPage"][0]');
      setContent(result);
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check if the aside section is scrollable
    if (asideRef.current) {
      setIsScrollable(asideRef.current.scrollWidth > asideRef.current.clientWidth);
    }
  }, [content]);

  const getIndexByProductType = (productType) => {
    switch (productType) {
      case "Denim":
        return 0;
      case "Shirting":
        return 1;
      case "Suiting":
        return 2;
      case "Tshirt-fabrics":
        return 3;
      case "Garments":
        return 4;
      default:
        return 0;
    }
  };

  const scrollToSection = (index) => {
    if (sectionRefs.current[index]) {
      sectionRefs.current[index].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (content) {
      const index = getIndexByProductType(productType);
      setActiveIndex(index);
      scrollToSection(index);
    }
  }, [content, productType]);

  const handleReadMoreClick = (sectionIndex) => {
    setReadMoreVisibleSections({
      ...readMoreVisibleSections,
      [sectionIndex]: true,
    });
  };

  const handleReadLessClick = (sectionIndex) => {
    setReadMoreVisibleSections({
      ...readMoreVisibleSections,
      [sectionIndex]: false,
    });
    // Ensure that the section scrolls back to the top
    scrollToSection(sectionIndex);
  };

  const handleNavigationClick = (index) => {
    const productTypes = ["Denim", "Shirting", "Suiting", "Tshirt-fabrics", "Garments"];
    if (productTypes[index]) {
      navigate(`/product/${productTypes[index]}`);
      setActiveIndex(index);
      scrollToSection(index); // Ensure the section is scrolled to on navigation click
    }
  };

  return (
    <div
      id="productDetail"
      className="bg-[#F6F7F6] flex lg:flex-row flex-col lg:justify-center mb-5 py-8 lg:my-16 lg:py-8 lg:px-[10%] overflow-hidden"
      style={{ backgroundImage: "url('/path-to-fabric-texture.png')" }} // Example fabric texture background
    >
      {/* Sidebar with product types */}
      <aside
        ref={asideRef}
        className="relative w-full min-h-14 lg:w-1/4 lg:min-h-[16rem] flex flex-row gap-4 justify-between lg:flex-col items-start lg:justify-start lg:space-y-4 overflow-x-auto lg:overflow-x-hidden whitespace-nowrap px-1 lg:py-4 lg:px-4 bg-[#f3f3f3] rounded-md"
      >
        {content &&
          content.products.map((item, index) => (
            <div
              key={index}
              className={`inline-flex gap-[5px] lg:gap-2 w-fit lg:w-full lg:flex items-center justify-between px-4 lg:px-5 lg:py-5 text-base lg:text-[1.6vw] leading-9 tracking-wider rounded-lg font-semibold capitalize cursor-pointer border-[2px] border-transparent transition-all duration-300 ${
                activeIndex === index
                  ? "playfair-display bg-[#6D7A65] text-white border-gray-300 shadow-lg"
                  : "playfair-display bg-white text-[#6D7A65] hover:bg-[#f2f2f2] hover:shadow-md"
              }`}
              onClick={() => handleNavigationClick(index)}
            >
              <div className="flex items-center gap-2">
                <span className="playfair-display">{item.title}</span>
                <div className="icon-container transition-transform transform hover:scale-110">
                  {activeIndex === index
                    ? item.icon && (
                        <img
                          src={urlFor(item.icon)}
                          alt="icon"
                          className="lg:w-6 lg:h-6 w-3"
                        />
                      )
                    : item.hoverIcon && (
                        <img
                          src={urlFor(item.hoverIcon)}
                          alt="icon"
                          className="lg:w-6 lg:h-6 w-3"
                        />
                      )}
                </div>
              </div>
              <div>
                {activeIndex === index
                  ? item.arrow && (
                      <img
                        src={urlFor(item.arrow)}
                        alt="icon"
                        className="lg:w-6 lg:h-6 w-3 transition-transform transform hover:rotate-180"
                      />
                    )
                  : item.hoverArrow && (
                      <img
                        src={urlFor(item.hoverArrow)}
                        alt="icon"
                        className="lg:w-6 lg:h-6 w-3"
                      />
                    )}
              </div>
            </div>
          ))}

        {/* Scroll Indicators */}
        {isScrollable && (
          <>
            {/* Left scroll indicator */}
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gradient-to-r from-gray-300 to-transparent w-8 h-8 rounded-full flex items-center justify-center">
              <span className="material-icons text-gray-600 animate-bounce-left">
                arrow_back
              </span>
            </div>
            {/* Right scroll indicator */}
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gradient-to-l from-gray-300 to-transparent w-8 h-8 rounded-full flex items-center justify-center">
              <span className="material-icons text-gray-600 animate-bounce-right">
                arrow_forward
              </span>
            </div>
          </>
        )}
      </aside>

      {/* Main content area */}
      <main className="right-content lg:w-3/4 w-full lg:min-h-[10rem] lg:overflow-auto overflow-x-hidden lg:overflow-y-scroll flex flex-col items-start space-y-8 p-4 rounded-md">
        {content &&
          content.products[getIndexByProductType(productType)].sections.map(
            (section, sectionIndex) => (
              <section
                key={sectionIndex}
                className="w-full relative mt-0 transition-all duration-500 min-h-40 md:min-h-64"
                ref={(el) => (sectionRefs.current[sectionIndex] = el)}
              >
                <div className="w-full relative rounded-lg overflow-hidden min-h-48 md:min-h-60">
                  {section.bgImage && (
                    <img
                      src={urlFor(section.bgImage)}
                      alt="background"
                      className="lg:w-full w-full min-h-48 lg:max-h-80 lg:px-0 px-[%] object-fill rounded-lg"
                    />
                  )}
                  <div
                    className={`absolute left-0 right-0 bottom-0 px-4 py-4 md:py-6 lg:py-8 text-white flex flex-col lg:items-start space-y-4 bg-gradient-to-t from-black/70 to-transparent rounded-lg ${
                      readMoreVisibleSections[sectionIndex]
                        ? "short-description "
                        : "short-description top-4"
                    }`}
                  >
                    <p className="playfair-display text-2xl font-bold">
                      {section.shortDescription}
                    </p>
                    {!readMoreVisibleSections[sectionIndex] && (
                      <div className="w-full flex flex-col items-start space-y-4 lg:space-y-8">
                        <p className="font-normal text-[#fff] text-[14px] lg:text-[1rem] lg:leading-[1.8rem] text-wrap pr-1 lg:pr-4">
                          {section.longDescription}
                        </p>
                        <div className="flex items-center gap-2">
                          <button
                            className="read-more-btn bg-white w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-full shadow-lg hover:bg-gray-200 transition-all duration-300"
                            onClick={() => handleReadMoreClick(sectionIndex)}
                          >
                            <img src={plus} alt="read more" className="w-5" />
                          </button>
                          <span className="montserrat ml-2 font-semibold text-white">
                            Read More
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {readMoreVisibleSections[sectionIndex] && (
                  <div className="read-more-content bg-[#6D7A65] w-full text-white p-4 lg:m-0 rounded-b-lg shadow-lg mt-">
                    <p className="font-normal text-[#fff] text-base lg:text-[1.25vw] lg:leading-10 text-wrap pr-1 lg:pr-4">
                      {section.longDescription}
                    </p>
                    <p className="montserrat lg:text-[1.8vw] lg:leading-[2.35vw] font-bold my-4">
                      {section.heading}
                    </p>
                    <ul className="flex flex-col items-start space-y-3 my-4">
                      {section.moreData.map((item, index) => (
                        <li
                          key={index}
                          className="list-item-decoration text-[#fff] p-2 text-[1rem] leading-6 lg:text-[1.25vw]"
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div className="flex items-center gap-2 mt-4">
                      <button
                        className="flex items-center justify-center bg-[#2d2d2d] w-[2.5rem] h-[2.5rem] rounded-full shadow-lg hover:bg-[#1d1d1d] transition-all duration-300"
                        onClick={() => handleReadLessClick(sectionIndex)}
                      >
                        <img src={minus} alt="read less" className="w-5" />
                      </button>
                      <span className="text-white">Read Less</span>
                    </div>
                  </div>
                )}
              </section>
            )
          )}
      </main>
    </div>
  );
}

export default DetailPage2;
