// src/components/HomeSec5.js
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import client from "../../sanityCli";
import "./HomeSec5.css";
import { Link, useNavigate } from "react-router-dom";

function HomeSec5() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec5"][0]{
          heading,
          title,
          products[]{
            image{
              asset->{
                _id,
                url
              }
            },
            icon{
              asset->{
                _id,
                url
              }
            },
            label
          }
        }`
      )
      .then((data) => {
        setData(data);
        console.log("data", data);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  }, []);

  const handleNavigationClick = (index) => { // Define handleNavigationClick
    const productTypes = ["Denim", "Shirting","Suiting"];
    if (productTypes[index]) {
      navigate(`/product/${productTypes[index]}`);
      setActiveIndex(index); // Set the active index when clicking on a navigation item
    }
  };


  if (error) return <div>Error</div>;
  if (!data) return <div></div>;

  return (
    <div
      id="ourProducts"
      className="our-products px-[5%] py-[5%] flex flex-col items-center lg:my-0 my-8"
      style={{ background: "#F6F7F6" }}
    >
      <div className="flex flex-col items-center justify-center space-y-4 lg:space-y-10">
        <p className="monstrrate text-[#6D7A65] text-base font-semibold leading-10 lg:text-[1.75vw] lg:eading-[2.75vw]">
          {data.heading}
        </p>
        <h1 className="text-[#2D2D2D] text-2xl playfair-display font-semibold lg:text-[3.75vw] lg:leading-[5.25vw] text-center">
          {data.title}
        </h1>
      </div>
      <div className="product-box-container w-full flex flex-col lg:flex-row items-center justify-center gap-4 my-[5%]">
      {data.products &&
        data.products.map((product, index) => (
          <div
            key={product.image.asset._id || index}
            className={`relative w-full lg:w-1/3 ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleNavigationClick(index)}
          >
            <img src={product.image.asset.url} alt={product.label} />
            <div className="absolute bottom-4 left-24 lg:left-32 bg-white rounded-full lg:min-w-[13rem] hover:scale-105 translate-x-0 lg:h-[5vw] w-[10.5rem] h-[3rem] p-4 lg:px-[5%] lg:py-1 flex items-center justify-center gap-2 lg:gap-3">
              <div className="icon-bg w-10 h-10 lg:w-[3rem] lg:h-[3rem] rounded-full flex items-center justify-center">
                <img
                  src={product.icon.asset.url}
                  alt={product.label}
                  className="lg:w-[2rem] lg:h-[2rem] w-[1rem] h-[1rem]"
                />
              </div>
              <p className="text-[#63715B] montserrat text-2xl lg:text-[1.8vw] leading-[2.65vw] font-bold">
                {product.label}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeSec5;

// import React, { useEffect, useState } from "react";
// import "./HomeSec5.css";
// import "../../../index.css";
// import icon from "./icon.svg";
// import image1 from "./image1.png";
// import image2 from "./image2.png";
// import image3 from "./image3.png";
// import image4 from "./image4.png";
// import client from "../../sanityCli";

// function HomeSec5() {
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     client
//       .fetch(
//         `*[_type == "homeSec5"][0]{
//           heading,
//           title,
//           products[]{
//             image{
//               asset->{
//                 _id,
//                 url
//               }
//             },
//             icon{
//               asset->{
//                 _id,
//                 url
//               }
//             },
//             label
//           }
//         }`
//       )
//       .then((data) => {
//         setData(data);
//         console.log("data", data);
//       })
//       .catch((err) => {
//         console.error(err);
//         setError(err);
//       });
//   }, []);

//   if (error) return <div>Error loading data.</div>;
//   if (!data) return <div>Loading...</div>;

//   return (
//     <div
//       id="ourProducts"
//       className="our-products px-[5%] py-[5%] flex flex-col items-center "
//       style={{ background: "#F6F7F6" }}
//     >
//       <div className="flex flex-col items-center justify-center space-y-10">
//         <div className="mt-4 sm:mt-6 text-center">
//           <p className="heading-color text-[1.75vw] leading-[2.75vw]">
//             our products
//           </p>
//           <h1 className="text-black text-[3.75vw] leading-[5.25vw] text-center">
//             Explore a Wide Variety Of Textiles Specially Crafted For You!
//           </h1>
//         </div>
//       </div>
//       <div className="product-box-container w-full flex flex-wrap items-center justify-center gap-6 my-[5%]">
//         <div className="relative  w-full sm:w-1/2 md:w-1/3">
//           <img src={image1} alt="/" />
//           <div className="absolute bottom-16 left-32 bg-white rounded-full w-fit px-[5%]  py-1 flex items-center  gap-5">
//             <span className="icon-bg w-[4vw] h-[4vw] rounded-full flex items-center justify-center">
//               <img src={icon} alt="/" className="w-[2vw]" />
//             </span>
//             <p className="heading-color monestrrate text-[1.8vw] leading-[2.65vw] font-semibold">
//               suiting
//             </p>
//           </div>
//         </div>
//         <div className="relative w-1/3">
//           <img src={image2} alt="/" />
//           <div className="absolute bottom-16 left-32 bg-white rounded-full w-fit px-[5%]  py-1 flex items-center  gap-5">
//             <span className="icon-bg w-[4vw] h-[4vw] rounded-full flex items-center justify-center">
//               <img src={icon} alt="/" className="w-[2vw]" />
//             </span>
//             <p className="heading-color monestrrate text-[1.8vw] leading-[2.65vw] font-semibold">
//               suiting
//             </p>
//           </div>
//         </div>
//         <div className="relative w-1/3">
//           <img src={image3} alt="/" />
//           <div className="absolute bottom-16 left-32 bg-white rounded-full w-fit px-[5%]  py-1 flex items-center  gap-5">
//             <span className="icon-bg w-[4vw] h-[4vw] rounded-full flex items-center justify-center">
//               <img src={icon} alt="/" className="w-[2vw]" />
//             </span>
//             <p className="heading-color monestrrate text-[1.8vw] leading-[2.65vw] font-semibold">
//               suiting
//             </p>
//           </div>
//         </div>
//         <div className="relative w-1/3">
//           <img src={image4} alt="/" />
//           <div className="absolute bottom-16 left-32 bg-white rounded-full w-fit px-[5%]  py-1 flex items-center  gap-5">
//             <span className="icon-bg w-[4vw] h-[4vw] rounded-full flex items-center justify-center">
//               <img src={icon} alt="/" className="w-[2vw]" />
//             </span>
//             <p className="heading-color monestrrate text-[1.8vw] leading-[2.65vw] font-semibold">
//               suiting
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default HomeSec5;
